import { Visit } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Typography } from "@cur8/maneki";
import { AppointmentCard } from "render/ui/presentation/AppointmentCard";
import { useRoomQuery } from "hooks/queries/useRoomQuery";
import { usePatientQuery } from "hooks/queries/usePatientQuery";
import styles from "./styles.module.sass";
import { VisitStatus } from "render/views/SessionJoinCompleteView/visitStatus";
import { useVisitHistoryQuery } from "hooks/queries/useVisitHistoryQuery";
import { generateVisitTypeLabel } from "lib/generateVisitTypeLabel";

export interface OutOfRangeViewProps {
  status: VisitStatus;
  visit: Visit;
  onContinue(): void;
  isBusy: boolean;
}

export function OutOfRangeView({
  status,
  visit,
  onContinue,
  isBusy,
}: OutOfRangeViewProps) {
  const nav = {
    start: useNav(paths.root),
  };

  const patientId = visit.patientId;
  const roomQuery = useRoomQuery({ roomId: visit?.roomId });
  const { data: visitHistory } = useVisitHistoryQuery({ patientId });
  const patientQuery = usePatientQuery({ patientId: patientId });
  const room = roomQuery.data;
  const member = patientQuery.data;
  const fullName = `${member?.name?.firstName} ${member?.name?.lastName}`;
  const formattedVisitStart = visit.startTime.toFormat("HH:mm");
  const time = `${formattedVisitStart} - ${visit?.endTime.toFormat("HH:mm")}`;
  const roomName = room?.displayName;
  const visitTypeLabel =
    visit != null && visitHistory != null
      ? generateVisitTypeLabel({ visit, visitHistory })
      : undefined;

  return (
    <PageFrameContent>
      <InformationLayout
        align="start"
        content={
          <section className={styles.layout}>
            <div className={styles.title}>
              {status === VisitStatus.Early && (
                <>
                  <Typography variant="display-s">Check in is early</Typography>
                  <Typography variant="body-m" color="subtle">
                    Member's appointment is at {formattedVisitStart}. Would you
                    like to continue the check in anyways?
                  </Typography>
                </>
              )}

              {status === VisitStatus.Late && (
                <>
                  <Typography variant="display-s">Check in is late</Typography>
                  <Typography variant="body-m" color="subtle">
                    Member's appointment was at {formattedVisitStart}. Would you
                    like to continue the check in anyways?
                  </Typography>
                </>
              )}
            </div>
            <AppointmentCard
              topLeft={fullName}
              topRight={roomName}
              bottomLeft={visitTypeLabel}
              bottomRight={time}
            />
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                onClick={() => nav.start.go({})}
                hideIcon
                direction="backward-centered"
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                variant="suggestion"
                onClick={onContinue}
                busy={isBusy}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
