import { Visit } from "@cur8/rich-entity";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo } from "react";
import { paths } from "render/routes/paths";
import { ConfirmedView } from "./components/ConfirmedView";
import { OutOfRangeView } from "./components/OutOfRangeView";
import { VisitNotFoundView } from "./components/VisitNotFoundView";
import { useFetchSessionQuery } from "hooks/queries/useFetchSessionQuery";
import { useVisitToCheckInQuery } from "hooks/queries/useVisitToCheckInQuery";
import { VisitStatus } from "render/views/SessionJoinCompleteView/visitStatus";
import { useStartCheckInMutation } from "hooks/mutations/useStartCheckInMutation";
import { useUpdateCheckInSessionMutation } from "hooks/mutations/useUpdateCheckInSessionMutation";
import { APITypesV1 } from "@cur8/api-client";

interface SessionJoinCompleteViewProps {
  goTo(url: string): void;
  onBack(): void;
  sessionId: string;
}

export function SessionJoinCompleteView({
  goTo,
  onBack,
  sessionId,
}: SessionJoinCompleteViewProps) {
  const { data: session } = useFetchSessionQuery({
    sessionId,
  });

  const startCheckInMutation = useStartCheckInMutation();

  const now = DateTime.now();

  const visitToCheckInQuery = useVisitToCheckInQuery({
    patientId: session?.patientId,
    origin: now,
  });

  const visit = useMemo(() => {
    return visitToCheckInQuery.data;
  }, [visitToCheckInQuery.data]);

  const { mutate: updateCheckInSessionMutation } =
    useUpdateCheckInSessionMutation();

  useEffect(() => {
    if (!visit) {
      return;
    }

    return updateCheckInSessionMutation({
      sessionId,
      visitId: visit.visitId,
    });
  }, [sessionId, updateCheckInSessionMutation, visit]);

  const isBodyScan = useMemo(() => {
    return visit?.medicalExams?.includes(APITypesV1.MedicalExam.NekoBodyScan);
  }, [visit?.medicalExams]);

  const url = useMemo(() => {
    if (visit) {
      return isBodyScan
        ? paths.awaitingConsent.url({
            patientId: visit.patientId,
            visitId: visit.visitId,
          })
        : paths.assignToRoom.url({
            patientId: visit.patientId,
            visitId: visit.visitId,
          });
    }
  }, [isBodyScan, visit]);

  const handleContinue = useCallback(() => {
    if (url != null && visit != null) {
      if (visit.status === APITypesV1.VisitStatus.CheckedIn) {
        return goTo(url);
      }

      startCheckInMutation.mutate(
        { patientId: visit.patientId, visitId: visit.visitId },
        { onSuccess: () => goTo(url) }
      );
    }
  }, [goTo, startCheckInMutation, url, visit]);

  if (visitToCheckInQuery.status === "pending") {
    return;
  }

  if (!visit) {
    return <VisitNotFoundView />;
  }

  const status = validateVisitWithinBounds(visit);

  if (status === VisitStatus.Valid) {
    return (
      <ConfirmedView
        onContinue={handleContinue}
        isBusy={startCheckInMutation.isPending}
        onBack={onBack}
        visit={visit}
      />
    );
  }

  return (
    <OutOfRangeView
      status={status}
      visit={visit}
      onContinue={handleContinue}
      isBusy={startCheckInMutation.isPending}
    />
  );
}

const LATE_CHECKIN_MAX_DIFF_IN_MINUTES = 10;
const EARLY_CHECKIN_MAX_DIFF_IN_MINUTES = 10;

function validateVisitWithinBounds(visit: Visit): VisitStatus {
  const visitStart = visit.startTime;
  const diff = visitStart.diffNow("minutes").minutes;

  if (diff < -LATE_CHECKIN_MAX_DIFF_IN_MINUTES) {
    return VisitStatus.Late;
  }

  if (diff > EARLY_CHECKIN_MAX_DIFF_IN_MINUTES) {
    return VisitStatus.Early;
  }

  return VisitStatus.Valid;
}
