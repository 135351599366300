import { Visit } from "@cur8/rich-entity";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Typography } from "@cur8/maneki";
import { useRoomQuery } from "hooks/queries/useRoomQuery";
import { usePatientQuery } from "hooks/queries/usePatientQuery";
import { AppointmentCard } from "render/ui/presentation/AppointmentCard";
import styles from "./styles.module.sass";
import { generateVisitTypeLabel } from "lib/generateVisitTypeLabel";
import { useVisitHistoryQuery } from "hooks/queries/useVisitHistoryQuery";

interface ConfirmedViewProps {
  visit: Visit;
  onContinue(): void;
  onBack(): void;
  isBusy: boolean;
}

export function ConfirmedView({
  visit,
  onContinue,
  onBack,
  isBusy,
}: ConfirmedViewProps) {
  const roomQuery = useRoomQuery({ roomId: visit?.roomId });
  const room = roomQuery.data;
  const patientQuery = usePatientQuery({ patientId: visit.patientId });
  const member = patientQuery.data;

  const fullName = `${member?.name?.firstName} ${member?.name?.lastName}`;
  const time = `${visit?.startTime.toFormat(
    "HH:mm"
  )} - ${visit?.endTime.toFormat("HH:mm")}`;
  const roomName = room?.displayName;

  const patientId = visit.patientId;
  const { data: visitHistory } = useVisitHistoryQuery({ patientId });
  const visitTypeLabel =
    visit != null && visitHistory != null
      ? generateVisitTypeLabel({ visit, visitHistory })
      : undefined;

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <div className={styles.title}>
              <Typography variant="display-s">Confirm check-in</Typography>
              <Typography variant="body-m" color="subtle">
                Confirm that this is the correct member
              </Typography>
            </div>
            <AppointmentCard
              topLeft={fullName}
              topRight={roomName}
              bottomLeft={visitTypeLabel}
              bottomRight={time}
            />
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                variant="suggestion"
                busy={isBusy}
                onClick={onContinue}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
