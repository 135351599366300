import { APITypesV1 } from "@cur8/api-client";
import { Icon16Alert, Icon16Checkmark, Typography } from "@cur8/maneki";
import { Visit } from "@cur8/rich-entity";
import { useVisitQuery } from "hooks/queries/useVisitQuery";
import { CheckInParams } from "lib/types/checkInParameters";
import { useCallback, useEffect, useMemo } from "react";
import { paths } from "render/routes/paths";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { List } from "render/ui/presentation/List";
import { Link } from "render/ui/trigger/Link";
import styles from "./styles.module.sass";

type CheckInCompleteViewProps = {
  goTo(url: string): void;
} & CheckInParams;

export function CheckInCompleteView({
  goTo,
  patientId,
  visitId,
}: CheckInCompleteViewProps) {
  const { data: visit } = useVisitQuery({
    patientId,
    visitId,
    options: { refetchInterval: 5000 },
  });

  const skinScanState = readScanStateFromVisit("skin", visit);
  const cardioScanState = readScanStateFromVisit("cardio", visit);
  const tissueScanState = readScanStateFromVisit("tci", visit);

  const goToNext = useCallback(() => {
    const url = paths.checkOut.url({ patientId, visitId });
    goTo(url);
  }, [goTo, patientId, visitId]);

  // If all scans are finished, go to the next step automatically
  useEffect(() => {
    if (
      skinScanState === APITypesV1.ImmutableScanState.Finished &&
      cardioScanState === APITypesV1.ImmutableScanState.Finished &&
      tissueScanState === APITypesV1.ImmutableScanState.Finished
    ) {
      goToNext();
    }
  }, [skinScanState, cardioScanState, tissueScanState, goToNext]);

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <Typography variant="display-s">Scan progress</Typography>
            <List>
              <List.Item>
                <div className={styles.scanItem}>
                  <div className={styles.itemTitle}>
                    <Typography variant="title-s">Skin Scan</Typography>
                    <Link
                      onPress={() =>
                        goTo(
                          paths.assignToSkinScanner.url({
                            patientId,
                            visitId,
                          })
                        )
                      }
                    >
                      Re-assign member
                    </Link>
                  </div>
                  {skinScanState != null ? (
                    <ScanStatusBadge scanState={skinScanState} />
                  ) : undefined}
                </div>
              </List.Item>
              <List.Divider />
              <List.Item>
                <div className={styles.scanItem}>
                  <div className={styles.itemTitle}>
                    <Typography variant="title-s">Cardio Scan</Typography>
                  </div>
                  {cardioScanState != null ? (
                    <ScanStatusBadge scanState={cardioScanState} />
                  ) : undefined}
                </div>
              </List.Item>
              <List.Divider />
              <List.Item>
                <div className={styles.scanItem}>
                  <div className={styles.itemTitle}>
                    <Typography variant="title-s">Tissue Scan</Typography>
                  </div>
                  {tissueScanState != null ? (
                    <ScanStatusBadge scanState={tissueScanState} />
                  ) : undefined}
                </div>
              </List.Item>
            </List>
          </section>
        }
      />
    </PageFrameContent>
  );
}

type ScanStatusBadgeProps = {
  scanState: APITypesV1.ImmutableScanState | undefined;
};

type ScanStatusBadgeHighlight = "success" | "error";

function ScanStatusBadge({ scanState }: ScanStatusBadgeProps) {
  const { label, highlight } = useMemo<{
    label: string;
    highlight?: ScanStatusBadgeHighlight;
  }>(() => {
    switch (scanState) {
      case APITypesV1.ImmutableScanState.Finished:
        return {
          label: "Completed",
          highlight: "success",
        };
      case APITypesV1.ImmutableScanState.Inprogress:
        return {
          label: "In progress",
          highlight: undefined,
        };
      case APITypesV1.ImmutableScanState.Available:
        return {
          label: "Ready",
          highlight: undefined,
        };
      case APITypesV1.ImmutableScanState.Failed:
        return {
          label: "Missing",
          highlight: "error",
        };
      default:
        return {
          label: "",
          highlight: undefined,
        };
    }
  }, [scanState]);

  return (
    <div className={styles.scanStatusBadge} data-highlight={highlight}>
      {highlight === "success" && <Icon16Checkmark />}
      {highlight === "error" && <Icon16Alert />}
      <Typography variant="eyebrow-m" as="span">
        {label}
      </Typography>
    </div>
  );
}

/**
 * Read scan state from the visit data for a given scan type (e.g. "skin", "cardio", "tci")
 * @returns ImmutableScanState (or undefined if the scan type is not found in the visit's immutableScansPerformed object)
 */
function readScanStateFromVisit(
  scanType: string,
  visit?: Visit
): APITypesV1.ImmutableScanState | undefined {
  if (visit?.immutableScansPerformed == null) {
    return undefined;
  }

  const scanTypeKey = Object.keys(visit.immutableScansPerformed).find((key) =>
    key.includes(scanType)
  );

  return visit.immutableScansPerformed[scanTypeKey ?? ""]?.resultState;
}
