import { useFinishCheckInMutation } from "hooks/mutations/useFinishCheckInMutation";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import { useRoomQuery } from "hooks/queries/useRoomQuery";
import { Typography } from "@cur8/maneki";
import { useFetchEndCheckInJobQuery } from "hooks/queries/useFetchEndCheckInJobQuery";
import { useEffect, useMemo, useState } from "react";
import { APITypesV1 } from "@cur8/api-client";
import { useReporting } from "render/hooks/useReporting";
import { CheckInParams } from "lib/types/checkInParameters";
import { useVisitQuery } from "hooks/queries/useVisitQuery";
import { usePatientQuery } from "hooks/queries/usePatientQuery";

type AssignToRoomProps = {
  goTo(url: string): void;
} & CheckInParams;

export function AssignToRoomView({
  goTo,
  patientId,
  visitId,
}: AssignToRoomProps) {
  const { data: patient } = usePatientQuery({ patientId });
  const { data: visit } = useVisitQuery({ patientId, visitId });
  const [jobId, setJobId] = useState<string | undefined>(undefined);

  const { logError } = useReporting();

  const roomQuery = useRoomQuery({ roomId: visit?.roomId });
  const roomName = roomQuery.data?.displayName;

  const finishCheckIn = useFinishCheckInMutation();
  const fetchEndCheckInJobQuery = useFetchEndCheckInJobQuery({
    jobId,
    patientId: patientId,
    visitId: visitId,
    options: {
      enabled: Boolean(jobId),
      refetchInterval: 1000,
    },
  });

  const isBodyScan = visit?.medicalExams?.includes(
    APITypesV1.MedicalExam.NekoBodyScan
  );
  const nextUrl = useMemo(() => {
    return isBodyScan
      ? paths.assignToSkinScanner.url({ patientId, visitId })
      : paths.checkOut.url({ patientId, visitId });
  }, [isBodyScan, patientId, visitId]);

  useEffect(() => {
    if (fetchEndCheckInJobQuery.data?.state === APITypesV1.JobState.Complete) {
      goTo(nextUrl);
      setJobId(undefined);
    }
    if (fetchEndCheckInJobQuery.data?.state === APITypesV1.JobState.Failed) {
      logError("Assign to room job failed");
      setJobId(undefined);
    }
    if (fetchEndCheckInJobQuery.error) {
      logError(fetchEndCheckInJobQuery.error);
    }
  }, [
    fetchEndCheckInJobQuery.data?.state,
    fetchEndCheckInJobQuery.error,
    goTo,
    logError,
    nextUrl,
  ]);

  const followUpExams = isBodyScan
    ? undefined
    : visit?.medicalExams?.join(", ");

  const memberName = patient?.name?.displayName || "the member";

  const subTitle = isBodyScan
    ? `When ${memberName} is ready to start the scan, assign them to the room.`
    : `${memberName}, Follow-up ${followUpExams}`;

  const buttonText = isBodyScan
    ? "Yes, assign to room"
    : "Begin follow-up exams";

  if (!patient) {
    return <BailView title="Could not fetch member" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <>
            <Typography variant="display-s">Are you in {roomName}?</Typography>
            <Typography variant="body-m" color="subtle">
              {subTitle}
            </Typography>
          </>
        }
        buttons={
          <ActionsFooter
            right={
              <ActionButton
                variant="suggestion"
                busy={finishCheckIn.isPending || Boolean(jobId)}
                onClick={async () => {
                  if (finishCheckIn.isPending) return;
                  finishCheckIn.mutate(
                    { patientId, visitId },
                    {
                      onSuccess: (data) => setJobId(data.jobId),
                    }
                  );
                }}
              >
                {buttonText}
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
